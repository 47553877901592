@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@400;600;700&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Noto Sans Lao","Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #f0f0f0;
}

/* Remove up/down scroll from Input type="number" */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.modal {
  top: '35%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  /* marginRight: '-50%'; */
  width: '60%';
  transform: 'translate(-40%, -10%)';
}

.swal2-container {
  z-index: 2000 !important;
}